import { Alert, VStack, HStack, Text, CloseIcon } from "native-base";
import { IconButtonWrapper } from "pages/Login/HiddenWrapper";
import { useTranslation } from "react-i18next";

export function getIconProp(variant) {
  return {
    color: variant === "solid" ? "lightText" : "darkText",
  };
}

export function getColor(variant) {
  return variant === "solid" ? "lightText" : variant !== "outline" ? "darkText" : null;
}

export function pressHandler(toast, id) {
  return () => toast.close(id);
}

export function shouldRenderIconButton(isClosable, variant, toast, id) {
  return isClosable ? (
    <IconButtonWrapper
      variant="unstyled"
      icon={<CloseIcon size="3" />}
      _icon={getIconProp(variant)}
      onPress={pressHandler(toast, id)}
    />
  ) : null;
}

const ToastAlert = ({ toast, id, status = "info", variant, title, description, isClosable, ...rest }) => {
  const { t } = useTranslation("common");
  return (
    <Alert maxWidth="100%" alignSelf="center" flexDirection="row" status={status} variant={variant} mx="15px" {...rest}>
      <VStack space={1} flexShrink={1} w="100%">
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <Text fontSize="md" fontWeight="medium" flexShrink={1} color={getColor(variant)}>
              {t(title)}
            </Text>
          </HStack>
          {shouldRenderIconButton(isClosable, variant, toast, id)}
        </HStack>
        <Text px="6" color={getColor(variant)}>
          {description}
        </Text>
      </VStack>
    </Alert>
  );
};

export default ToastAlert;
