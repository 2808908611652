import { Text, Button } from "native-base";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import AmplitudeHelper from "utils/analytics";
import { showComponents } from "pages/SingleCreate/utils";
import { getIfSDA } from "utils/utilities";
import { getStore } from "store/storeUtils";
import { useNavigate } from "react-router-dom";

export const checkIfActive = (route) => {
  if (route.path === "/" && location.pathname !== "/") {
    return route?.children?.some((subRoute) => {
      return location.pathname === subRoute.path;
    });
  }
  return location.pathname.startsWith(route.path);
};

const RouteButton = ({ route = "", isFileUploading = false, showModalForDisableUpload = () => {} }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { employerConfigData } = getStore();
  const isCurrentRoute = checkIfActive(route);

  const isSda = getIfSDA(employerConfigData);
  if (!employerConfigData) return null;

  if (!isSda && route?.isSDA) return null;
  if (showComponents(employerConfigData, route))
    return (
      <Button
        testID="btnRoute"
        variant="rounded"
        bg={isCurrentRoute ? colors.neutral.cotton : colors.secondary.orchid}
        borderWidth={1}
        borderColor={colors.neutral.cotton}
        my="7px"
        onPress={() => {
          if (!isFileUploading) {
            AmplitudeHelper.logEvent(route?.amplitudeEvent);
            navigate(route?.path);
          } else {
            showModalForDisableUpload();
          }
        }}
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Text
          variant={isCurrentRoute ? "xs-bold" : "xs"}
          color={isCurrentRoute ? colors.neutral.charcoal : colors.neutral.cotton}
          alignItems="center"
        >
          {route?.icon}
          {"  "}
          {t(route?.name)}
        </Text>
      </Button>
    );
};

export default RouteButton;
