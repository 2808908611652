import React from "react";
import { Hidden, Alert, IconButton } from "native-base";

export const HiddenWrapper = ({ children, ...props }) => {
  return <Hidden {...props}>{children}</Hidden>;
};

export const AlertIconWrapper = (props) => {
  return <Alert.Icon {...props} />;
};

export const IconButtonWrapper = (props) => {
  return <IconButton {...props} />;
};
