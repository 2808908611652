import AmplitudeHelper from "utils/analytics";
import { Center, Image, Text, Button, Box, View, VStack } from "native-base";
import { SentryHelper } from "utils/performance/SentryHelper";
import React, { Component } from "react";
import { FixingImage } from "assets";
import colors from "theme/colors";
import { withTranslation } from "react-i18next";
import { IoIosCloseCircle } from "react-icons/io";

const styles = {
  box: {
    backgroundColor: colors.error.dustRed1,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: colors.error.dustRed,
    padding: 16,
    marginBottom: 16,
  },
  infoIconContainer: { marginRight: "12px" },
};

export function shoulRenderForNavBar(t) {
  return (
    <VStack w="100%" justifyContent="flex-start" style={styles.box}>
      <View flexDirection="row">
        <VStack>
          <IoIosCloseCircle
            data-testid="navBarFallback"
            size="18px"
            color={colors.error.rose}
            style={styles.infoIconContainer}
          />
        </VStack>
        <VStack>
          <Text maxW="35%" fontSize="xs" fontWeight="medium" flexWrap="wrap">
            {t("errorFallBack.sideBarTitle")}
          </Text>

          <Box pr="30px" mr="10px" mt="4px" borderRadius={12} flexWrap="wrap">
            <Text fontSize="xs" noOfLines={4} maxW="40%">
              {t("errorFallBack.sideBarDesc")}
            </Text>
          </Box>
        </VStack>
      </View>
    </VStack>
  );
}
class ErrorFallBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.refreshPage = this.refreshPage.bind(this);
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
    });
    // You can also log error messages to an error reporting service here
    SentryHelper.captureException(error);
    AmplitudeHelper.logEvent("internal_server_error_toast", {
      failure_message: error?.message || error?.stack,
    });
  }

  refreshPage() {
    const { navigate } = this.props;
    navigate("/");
  }

  render() {
    const { children, t, isNavBar } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      if (isNavBar) {
        return shoulRenderForNavBar(t);
      }
      return (
        <Center flex={1}>
          <Image alt="fixImage" src={FixingImage} style={{ width: "328px", height: "172px" }} />
          <Text fontWeight="700" color={colors.neutral.charcoal}>
            {t("errorFallBack.title")}
          </Text>
          <Text fontWeight="400" color={colors.neutral.charcoal} mt="8px">
            {t("errorFallBack.desc")}
          </Text>
          <Button variant="solid" mt="24px" onPress={this.refreshPage}>
            <Text variant="xs" color="white">
              {t("errorFallBack.refresh")}
            </Text>
          </Button>
        </Center>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorFallBack.defaultProps = {
  children: null,
  isNavBar: false,
};

export default withTranslation("common")(ErrorFallBack);
